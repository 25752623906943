@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.hola {
  position: relative; width: 100%; height: 0; padding-top: 50.0000%;
 padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
 border-radius: 8px; will-change: transform;
}

.hola2 {
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

/* Inspired by twitter.com/marina_uiux */
.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  /* border: 3px solid #ffffff4d; */
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.button-green{
  background-color: #28a745;
}

.button-gold{
  background-color: gold;
}
.button-black{
  background-color: #141417;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}



.button:hover {
  transform: scale(1.05);
  border-color: #fff9;
} 

.button:hover .icon {
  transform: translate(4px);
}

.button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}


.send-button {
  font-family: inherit;
  font-size: 20px;
  background: royalblue;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  height: 70px;
}

.send-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.send-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.send-button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.send-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.send-button:hover span {
  transform: translateX(5em);
}

.send-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;
  position: relative;
}

.loader:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
}

.loader-inner {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

.loader-block {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 2px;
  background-color: rgba(179, 136, 8, 0.886);
  box-shadow: 0 0 20px #fff;
  animation: loader_562 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-block:nth-child(1) {
  animation-delay: 0.1s;
}

.loader-block:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-block:nth-child(3) {
  animation-delay: 0.3s;
}

.loader-block:nth-child(4) {
  animation-delay: 0.4s;
}

.loader-block:nth-child(5) {
  animation-delay: 0.5s;
}

.loader-block:nth-child(6) {
  animation-delay: 0.6s;
}

.loader-block:nth-child(7) {
  animation-delay: 0.7s;
}

.loader-block:nth-child(8) {
  animation-delay: 0.8s;
}

@keyframes loader_562 {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(179, 136, 8, 0.345);
  }

  20% {
    transform: scale(1, 2.5);
    box-shadow: 0 0 50px rgba(174, 142, 45, 0.471);
  }

  40% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(174, 137, 28, 0.886);
  }
}

.language-selector {
  display: flex;
  align-items: center;
}

.language-selector button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 5px;
}

.language-selector button img.flag-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.language-selector button.active {
  cursor: default;
  color: grey;
}

.language-selector button:disabled {
  opacity: 0.5;
}

.css-1ytufz-MuiAvatarGroup-root {
  justify-content: center;
}

.button-evidences {
  width: fit-content;
  display: flex;
  padding: 0.6em 1rem;
  cursor: pointer;
  gap: 0.4rem;
  font-weight: bold;
  border-radius: 10px;

  background-size: 300%;
  text-shadow: 2px 2px 3px rgba(0, 136, 0, 0.5);
  background: linear-gradient(to right, #d6aa25, #835c0e);
  color: #fff;
  border: none;
  background-position: left center;
  box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
  transition: background .3s ease;
}

.button-evidences:hover {
  background-size: 320%;
  background-position: right center;
}

.button-evidences:hover svg {
  fill: #fff;
}

.button-evidences svg {
  width: 23px;
  fill: #f6f6f6;
  transition: .3s ease;
}

.p-progressbar-value {
  background: linear-gradient(90deg, 
  rgb(0 107 179) ,rgb(3, 77, 156)
) !important;
background-size: 400% 100%;
animation: gradientMove 5s linear infinite;
}


@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.button-evidences svg {
  animation: rotate 2s linear infinite;
  height: 20px;
}


.button-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust this value to increase or decrease space between buttons */
  padding: 20px;
}

.brutalist-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #000000;
  border-radius: 12px;
  padding: 0;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  position: relative;
  box-shadow: 4px 4px 0px #000000;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 70px;
  width: 70px;
  cursor: pointer;
}

.brutalist-button.openai {
  background-color: #356854;
}

.brutalist-button::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -150%;
  width: 300%;
  height: 300%;
  border-radius: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.brutalist-button.openai::before {
  background-color: #316b58;
}

.brutalist-button.claude::before {
  background-color: #e3d19c;
}

.brutalist-button:hover::before {
  transform: translateX(-50%) scale(1);
}

.brutalist-button:hover {
  transform: translate(-4px, -4px);
  box-shadow: 8px 8px 0px #000000;
}

.brutalist-button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px #000000;
}

.openai-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.openai-logo {
  align-items: center;
  justify-content: center;
  background-color: #0f1715; /* OpenAI's green color */
  border-radius: 50%; /* This creates the circular background */
}

.openai-icon {
  width: 44px;
  height: 44px;
  fill: #ffffff; /* White color for the SVG */
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.brutalist-button:hover .openai-logo {
  animation: spin 5s linear infinite;
  width: 30px;
  height: 30px;
  top: 28%;
}

.brutalist-button:hover .openai-icon {
  transform: scale(0.6);
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  z-index: 2;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
}

.button-text span:first-child {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
}

.button-text span:last-child {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  color: #ffffff;
}

.brutalist-button:hover .button-text {
  opacity: 1;
  transform: translateY(0);
}

.brutalist-button.openai:hover .button-text {
  color: #d3d3d3;
}

.brutalist-button.openai:hover .button-text span:last-child {
  color: #d6cbbf;
}

@media (hover: hover) and (pointer: fine) {
  .brutalist-button:hover {
    transform: translate(-4px, -4px);
    box-shadow: 8px 8px 0px #000000;
  }
}

.l {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.o {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.a {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.i {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.n {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.g {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d1 {
  color: black;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 1.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d2 {
  color: black;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

@keyframes pass {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pass1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.event-morning {
  background: #e5f3ff; /* Fondo dorado claro */
  border-left: 5px solid #0072C6; /* Borde dorado fuerte */
  color: #212121 !important; /* Color de fuente */
}

.event-night {
  background: #e3d9fb; /* Fondo azul claro */
  border-left: 5px solid #6411a4; /* Borde azul fuerte */
  color: #212121 !important; /* Color de fuente */
}

.event-afternoon {
  background: #d9fbdc; /* Fondo azul claro */
  border-left: 5px solid #00c621 ; /* Borde azul fuerte */
  color: #212121 !important; /* Color de fuente */
}


.fc-v-event .fc-event-title{
  color: #151515  ;
  padding: 10px;
}

.fc-event-time{
  color: #151515 !important;
  font-weight: 700;
  margin: 10px;
}


.e-headercelldiv{
  padding: 0;
}



/* From Uiverse.io by Creatlydev */ 
.card-course {
  margin: auto;
  width: min(30%, 100%);
  background-color: #fefefe;
  border-radius: 1rem;
  padding: 0.4rem;
  color: #141417;
}
.card__hero {
  background-color: #e2e3fe;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1.5rem;
  padding-top: 40px;
  font-size: 0.875rem;
}
.card__hero .card__job-title {
  margin: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  padding-right: 2rem;
   visibility: hidden; 
}
.card__hero-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  font-weight: 700;
}
.card__footer {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0.75rem;
  row-gap: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
}
@media (min-width: 340px) {
  .card__footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}
.card__job-summary {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.75rem;
}

