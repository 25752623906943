.button-send-email-and-verification {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition: width 0.3s ease, border-radius 0.3s ease;
  overflow: hidden;
  position: relative;
  margin-left: 10px;
}

.svgIcon {
  width: 12px;
  transition: transform 0.3s ease;
}

.svgIcon path {
  fill: white;
}

.hover-effect {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%; /* Asegura que ocupa todo el espacio del contenedor */
  --primary-color: #111;
  --hovered-color: #c84747;
}

.hover-effect p {
  margin: 0;
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 600;
  transition: color 0.3s ease-out;
}

.hover-effect::after {
  content: "";
  position: absolute;
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: width 0.3s ease-out;
}

.hover-effect:hover::after {
  width: 100%;
}

.hover-effect:hover p {
  color: var(--hovered-color);
}

.dialog-fullscreen .p-dialog-header {
    background-color: #2E2E2E; 
    border-bottom: none; 
}