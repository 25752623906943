.custom-file-upload {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
}

.file-input-label .drop-area {
    padding: 20px;
    text-align: center;
    background: #f8f9fa;
    cursor: pointer;
}

.files-list {
    margin-top: 10px;
}

.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #f4f4f4;
    padding: 8px;
    border-radius: 4px;
}

.file-image-preview {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}

.file-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.file-size {
    white-space: nowrap;
}
    
.file-upload .drop-area {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload .drop-area:hover {
    background-color: #cce5ff;
    border: 2px dashed #007bff;
}

.file-upload .file-select-button {
    margin-bottom: 10px;
}

.file-upload .files-list .file-item {
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
}

.file-upload .submit-report-btn {
    width: 100%;
    background-color: #007bff;
    color: white;
}

.file-upload .drop-area.hover {
    border-color: #80bdff;
}




.swal2-confirm-button-success {
    background-color: rgb(50,135,64); 
    color: white;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-right: 0.5rem; 
}

    
.swal2-deny-button {
    background-color: #ff5f57; 
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;    
    margin-right: 0.5rem;
}
.swal2-cancel-button {
    background-color: gray; 
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
}

.custom-swal2-confirm {
    background-color: #007bff;
    color: #ffffff; 
    border: none;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.5);
    border-radius: 0.375rem;
    padding: 0.75rem 1.5rem;
    transition: background-color 0.3s;
}

.custom-swal2-confirm:hover {
    background-color: #0056b3; 
}

.my-form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-form-control .MuiFormControlLabel-root {
  margin-right: auto; 
}

.my-calendar {
  flex-grow: 1;
}

.wrong-property{
    z-index: 1500 !important;
}

.confirm-send-report .button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem; 
    gap: 10px;
    margin-top: 1rem;
}

.confirm-send-report .custom-button {
    margin-right: 0.5rem; 
}

.typewriter-text-new-report {
    font-size: 24px;
    /* font-family: 'Playfair Display', serif; */
    font-weight: bold;
    color: rgb(164, 127, 17);
    white-space: nowrap;
    overflow: hidden;
    margin-top: 5%;
    margin-bottom: 5;
}


.checkbox-wrapper {
  display: flex;
  align-items: center; /* Alinea verticalmente al centro */
  cursor: pointer;
}

.checkbox-wrapper *,
.checkbox-wrapper *::before,
.checkbox-wrapper *::after {
  box-sizing: border-box;
}

.checkbox-wrapper label {
  display: flex;
  align-items: center; /* Asegura que el texto y el checkbox estén alineados verticalmente */
}

.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
}

.checkbox-wrapper .checkbox {
  width: 25px;
  height: 25px;
  border: 3px solid #434343;
  border-radius: 6px;
  transition: all 0.375s;
  margin-right: 10%;
}

.checkbox-wrapper input:checked ~ .checkbox {
  transform: rotate(45deg);
  width: 14px;
  margin-left: 12px;
  border-color: #000000;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
}

.new-report-files-container {
  display: flex;
  height: 300px; 
}

.new-report-file-list {
  flex: 1;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
}


.new-report-file-image-preview, .new-report-file-video-preview {
  width: 100%; 
  height: auto;
}

.search-input {
  width: calc(100% - 10px);
  padding: 5px;
  margin: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.file-separator {
  height: 2px;
  background-color: #4c0000;
  margin-top: 5px;
}

.file-header {
  display: flex;
  justify-content: space-between; /* Alinea el nombre del archivo a la izquierda y el botón a la derecha */
  align-items: center;
  width: 100%; /* Asegura que el header ocupe todo el ancho del contenedor padre */
  padding: 5px 10px; /* Agrega un poco de padding para separación visual */
}

.file-name {
        flex: 1;
    margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Evita que el nombre del archivo desborde y lo corta con puntos suspensivos si es muy largo */
}

.margin-delete-button {
  margin-left: auto; /* Empuja el botón hacia la derecha */
}


