.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p{
  padding: 0;
}

.leaflet-popup{

  border: 0;
}
.leaflet-popup .leaflet-popup-content-wrapper{
  background: #2d2d2e;
  border-radius:0 ;
}
.leaflet-popup-tip-container{
  display: none;
}

.leaflet-popup-content{
  margin: 2px 1px !important;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(39, 39, 38);
}

.leaflet-popup-content p{
margin:8px 0;
}

/* Personalización de las alertas de SweetAlert2 */
.swal2-custom-popup {
  background-color: #f4f6f9 !important; /* Fondo más claro */
  color: #333 !important; /* Color de texto */
  border-radius: 10px !important; /* Esquinas redondeadas */
  font-family: 'Arial', sans-serif; /* Fuente personalizada */
  padding: 20px !important; /* Espaciado interno */
}

.swal2-custom-title {
  font-size: 18px !important; /* Tamaño del título */
  font-weight: bold !important; /* Título en negrita */
  color: #444 !important; /* Color del título */
}

.swal2-custom-html {
  font-size: 14px !important; /* Tamaño del texto */
  color: #666 !important; /* Color del texto */
}

/* Personalización de los íconos */
.swal2-icon.swal2-error {
  border-color: #e74c3c !important; /* Color del borde del ícono de error */
  color: #e74c3c !important; /* Color del ícono de error */
}

.swal2-icon.swal2-warning {
  border-color: #f39c12 !important; /* Color del borde del ícono de advertencia */
  color: #f39c12 !important; /* Color del ícono de advertencia */
}

.swal2-icon.swal2-success {
  border-color: #2ecc71 !important; /* Color del borde del ícono de éxito */
  color: #2ecc71 !important; /* Color del ícono de éxito */
}
