@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600&display=swap'); */

 *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
 
}

  body{
    font-family: 'Titillium Web', sans-serif;
  }
  
  input{
    border: 2px solid rgb(194, 136, 11);
  }
 
  
  textarea{
    border: 2px solid rgb(194, 136, 11);
  }

 