.margin {
    margin-top: 20px;
    margin-bottom: 20px;
}

.slider {
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slide-track {
    display: flex;
    width: calc(250 * 18);
    animation: scroll 60s linear infinite;
}

.slide-track:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-5000px);
    }
}

.slide {
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}

.img {
    width: 100%;
}

.margin-title {
    margin-right: 25rem;
}

.img:hover {
    transform: translate(20px);
}

.slider::before,
.slider::after {
    content: "";
    height: 100%;
    position:absolute;
    width: 15%;
    z-index: 2;
}

.slider::before{
    left: 0;
    top: 0;
    transform: rotate(180deg)
}

.tittle {
    color: chocolate;
    font-size: 40px;

}

.container-carrousel{
    margin-left: 50px;
    font-size: 30px;
    color: chocolate;
}

.img-logo{
    margin-top: -20px;
    height: auto;
    width: auto;
    position: relative;
    border-radius: 30px;
}

.tittle-info {
    right: 15px
}




.txtinfo {
    margin-top: 80px;
}


.info-tittle {
  font-size: 25px;
  color: #001f3f;
  position: absolute;
  right: 35%;
  bottom: 90%;
}


/* 
@media (max-width: 640px) {
  .info-tittle {
    right: 40%;
    bottom: 90%;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .info-tittle {
    right: 70%;
    bottom: 90%;
  }
}

@media (min-width: 1024px) {
  .info-tittle {
    right: 30px;
    bottom: 30px;
  }
} */


.info-paragraph-ideas {
    font-family: 'Poppins', sans-serif;
    position: absolute;
    right: 60%;
    bottom: 75%;
}

.info-paragraph-ideas-1 {
    position: absolute;
    right: 58%;
    bottom: 70%;
     font-family: 'Poppins', sans-serif;
}
.info-paragraph-ideas-2 {
    position: absolute;
    right: 58%;
    bottom: 65%;
    font-family: 'Poppins', sans-serif; 
}
.info-paragraph-ideas-3 {
    position: absolute;
    right: 58%;
    bottom: 60%;
    font-family: 'Poppins', sans-serif; 
}

.info-paragraph-stadistics {
    position: absolute;
    right: 17%;
    bottom: 75%;
    font-family: 'Poppins', sans-serif; 
}
.info-paragraph-stadistics1 {
    position: absolute;
    right: 19%;
    bottom: 70%;
    font-family: 'Poppins', sans-serif; 
}
.info-paragraph-stadistics2 {
    position: absolute;
    right: 20%;
    bottom: 65%;
    font-family: 'Poppins', sans-serif; 
}
.info-paragraph-stadistics3 {
    position: absolute;
    right: 29%;
    bottom: 60%;
    font-family: 'Poppins', sans-serif; 
}
.info-paragraph-trophy {
    position: absolute;
    right: 75%;
    bottom: 45%;
    font-family: 'Poppins', sans-serif; 
}

.fontsize{
        font-size: 20px;
        color: #001f3f;
}

.info-container{
    position: relative;
}

.infografia{
    height: 600px;
    width: 500px;
    border-radius: 30px;
}