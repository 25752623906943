.CTA {
  position: absolute !important;
  bottom: 10rem;
  right: 14rem;
  background-color: #8f0005;
  border-radius: 1.5em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.5em;
}

.monitoringSpace-suport{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); 
}