.navbar{
     height: 130px;
     padding: 20px 0 0 0;
     margin: 0;
     
     
 }
 
 .logo{
    height: 80px;
    width: 200px;
 }

.rr{
    background-color: rebeccapurple;
    position: absolute;
    z-index: 100000 !important;
}