.update-camera-button {
    color: #007ad9; 
    background-color: transparent; 
    padding: 10px 20px; 
    border-radius: 5px;
    transition: background-color 0.2s, color 0.2s; 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    border: none; 
}

.update-camera-button:hover {
    background-color: #007ad9;
    color: white; 
}

.swal-overlay {
    z-index: 1500 !important;  
}