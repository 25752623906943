.background {
  background-image: url("/src/assets/images/Backgrounds/fondo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 0.75rem;
  padding-bottom: 1.75rem;
  padding-top: 1.75rem;
}

#gridcomp {
  min-height: 500px;
}
.typewriter-text {
  font-size: 36px;
  /* font-family: 'Playfair Display', serif; */
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #d6aa25, #835c0e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 3.5s steps(40, end) forwards;
  padding-bottom: 15px;
}
.typewriter-text-secondary {
  font-size: 32px;
  /* font-family: 'Playfair Display', serif; */
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #d6aa25, #835c0e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: solid 3px #d6aa25;
  margin-bottom: 15px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: calc(100% - 0.5ch);
  }
}

.confirm-send-report .button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  gap: 10px;
  margin-top: 1rem;
}

.confirm-send-report .custom-button {
  margin-right: 0.5rem;
}

.custom-dialog {
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  align-items: center;
  background-color: rgb(56, 56, 57);
  color: white;
  padding: 1rem;
  border-bottom: 2px solid gold;
  position: relative;
}

.logo {
  /* width: 50px; */
  height: auto;
  margin-right: 1rem;
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
}

.dialog-content {
  display: flex;
  padding: 1rem;
}

.container {
  display: flex;
  width: 100%;
}

.form-section {
  flex: 1;
  padding-right: 1rem;
}

.details-section {
  flex: 1;
  padding-left: 1rem;
}

.custom-input,
.custom-textarea {
  margin-bottom: 1rem;
}

.custom-textarea {
  border-radius: 0; /* Bordes cuadrados */
}

.custom-image {
  width: 100%;
  margin-top: 8px;
  border-radius: 0; /* Bordes cuadrados */
}

.dialog-request {
  max-height: 700px;
  overflow-y: auto;
  z-index: 0;
}

.label-input-request {
  color: rgba(168, 133, 26, 0.986);
  font-weight: 600;
  display: block;
  width: auto;
  margin-top: 10px;
}

.form-request {
  padding: 15px 20px;
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 100000 !important;
}

.p-dropdown-panel {
  z-index: 100000 !important;
}

.identification-id {
  font-size: 30px;
  /* font-family: 'Playfair Display', serif; */
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #d6aa25, #835c0e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-label {
  color: #bb8416;
  font-weight: 600;
  font-size: 17px;
  /*  padding-bottom: 3px; */
}

.card-clock {
  width: 180px;
  height: 70px;
  border-radius: 15px;
  box-shadow: rgb(0, 0, 0, 0.7) 5px 10px 50px, rgb(0, 0, 0, 0.7) -5px 0px 250px;
  display: flex;
  color: white;
  justify-content: center;
  position: relative;
  flex-direction: column;
  /*   background: linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85)); */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-top: 15px;
  margin-left: 10px;
}

.card-clock:hover {
  box-shadow: rgb(0, 0, 0) 5px 10px 50px, rgb(0, 0, 0) -5px 0px 250px;
}

.time-text {
  font-size: 17px;
  margin-top: 0px;
  margin-left: 15px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.time-sub-text {
  font-size: 15px;
  margin-left: 5px;
}

.day-text {
  font-size: 18px;
  margin-top: 0px;
  margin-left: 15px;
  font-weight: 500;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.moon {
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.card-clock:hover > .moon {
  font-size: 23px;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.741);
}
/* .p-disabled{
  opacity: 6;
}

.p-disabled:disabled{
  opacity: 6;
}

.MuiChip-root.Mui-disabled{
  opacity: 6 ;
}  */

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.MuiChip-root.Mui-disabled {
  opacity: 0.8 !important;
}

.bg-gradient-desktop {
  background-color: rgb(25, 25, 25);
  background-image: radial-gradient(
      at 40% 20%,
      rgb(149, 149, 149) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, rgb(255, 213, 97) 0px, transparent 50%),
    radial-gradient(at 80% 100%, rgb(82, 82, 82) 0px, transparent 50%),
    radial-gradient(at 0% 0%, rgb(232, 232, 233) 0px, transparent 50%);
}
