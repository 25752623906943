.support-image{
   border-radius: 5%; 
   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); 
}

.support-content {
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform-origin: center;
}

.oculto {
  opacity: 0;
  transform: scale(0.95);
  display: none; /* Opcional, dependiendo de cómo quieras manejar la transición */
}