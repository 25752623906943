.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input-label {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.file-name {
  margin-top: 5px;
  font-size: 0.9rem;
  color: #555;
}

.p-error {
  color: red;
  margin-top: 5px;
}
