
.dialog-fullscreen {
    background-color: #2E2E2E; 
    color: white;
}

.banner-container img {
    width: 100%;
    height: auto;
}

.titulo-header {
    color: yellow; 
    font-size: 24px; 
    font-weight: bold;
}


.input-group {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.input-group .button-text {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.input-group .button-raised {
    background-color: #f44336; 
    color: white;
}

.form-container input, .form-container textarea {
    width: 100%;
    background-color: #333; 
    color: white;
    border: 1px solid #666;
    padding: 8px;
}


.titulo-header {
    font-size: 20px;
    color: rgb(234, 190, 63)
}

.fondo-body-send-email{
        background-color: #2E2E2E; 
}

.input-group:hover .cco-input {
    max-height: 100px; 
}

.image-gallery-container {
  max-width: 800px; 
  margin: auto; 
}

.video-gallery-container video {
  width: 100%;
  height: auto;
}

.titulo-images-videos{

    font-size: 25px;
    color: rgb(234, 190, 63)
}

.text-footer{
    color: white;
    font-size: 20px;
}

.index-swal{
    z-index: 1500 !important;
}

.suggestions-container {
    position: absolute;
    top: 100%; 
    background-color: #585858 ;
    width: 100%;
    max-height: 200px;
    z-index: 1000; /* Asegúrate de que el dropdown esté por encima de otros elementos */
    overflow-y: auto;
    border-radius: 8px;
    margin: 10px 0px;
}

.input-group:hover .suggestions-container {
  display: block; /* Mostrar cuando el input está activo */
  border: 1px solid rgb(130, 129, 128);
}

.suggestion-item {
    cursor: pointer;
    color: #e5e0e0;
    padding-left: 15px;
}

.suggestion-item:hover {
    background-color: rgb(104, 104, 102);
    color: #333; /* Un poco más oscuro al pasar el mouse */
}

.p-fluid .p-inputtext {
    background-color: #333;
    color: #cccccc;
    padding: 4px 5px;
}

.p-chips .p-chips-multiple-container .p-chips-token{
    margin-top: 6px;
    background-color: rgb(234, 190, 63);
}

.p-chips .p-chips-multiple-container .p-chips-input-token input{
    border: none;
    color: #f6f6f6;
}

.css-10hburv-MuiTypography-root{
    color:rgb(234, 190, 63)
}

.suggestion-item .MuiListItemText-secondary {
    color: #d7d7d7; /* Cambia el color del texto secundario */
  }

  