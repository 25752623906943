.span-services{
    width: 10px;
    height: 10px;
    background: #B78607;
    margin-right:10px;
}
.container-services{
  
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

}


.container-services-items{
    width: 30%;
    margin-bottom: 60px;
    
}
.services-images{
    width: 50%;
}
.iconos{
    color: rgb(3, 99, 244);

}

li {
    position: relative; 
}

.service-item::before{
    background-color: rgba(223, 172, 8, 0.63);
}
.service-item::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    position: relative;
    margin-right: 8px;
    top: -4px;
}

.info-box{
        opacity: 0.4;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        cursor: pointer;
    
}
.info-box1{
        opacity: 1;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        cursor: pointer;
    
}

h3 {

       font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding: 34px 10px 0 10px;
    font-weight: 700;
    letter-spacing: 1.08px;
    border-left: 2px solid #B78607;
    min-height: 92px;
    text-transform: uppercase;
}